import React, { PureComponent } from 'react';
import classNames from "classnames"
import Cookies from 'js-cookie'

import './styles.scss'

import swissUrl, {
	ReactComponent as SwissIcon,
} from '../../../static/images/icons/_swiss_black.svg'
import gdprUrl, {
	ReactComponent as GDPRIcon,
} from '../../../static/images/icons/_gdpr_black.svg'
import backdo0rUrl, {
	ReactComponent as BackdoorIcon,
} from '../../../static/images/icons/_nobckdr_black.svg'


class CookieInfo extends PureComponent {
	state = {
		isSwitherActive: true,
	}

	componentDidMount() {
		if (!Cookies.get('allowCookie')) {
			this.setState({
				isSwitherActive: false
			})
		}
	}

	handleClick() {
		this.setState({
			isSwitherActive: !this.state.isSwitherActive
		}, this.toogleCookie())
	}

	toogleCookie() {
		if (this.state.isSwitherActive) {
			Cookies.set('allowLightCookies', true)
		} else {
			Cookies.set('allowLightCookies', false)
		}
	}

	handleClickToFull() {
		this.setState({
			isSwitherActive: true
		}, this.toogleCookie())
	}

	handleClickToMin() {
		this.setState({
			isSwitherActive: false
		}, this.toogleCookie())
	}

	render() { 
		const {
			backdoorIcon,
			swissPrivacyIcon,
			gdprIcon,
			leftSideInfo,
			rightSideCookie,
			cookieSwitcherFull,
			cookieSwitcherRight,
			cookieInfoOn,
			cookieInfoOff,
			cookieInfo,
		} = this.props
		const { isSwitherActive } = this.state
		return ( 
			<section className="g-container g-container--double-pad">
				<div className="b-cookie-info">
					<div className="b-cookie-info__row">
						<div className="b-cookie-info__left">
						</div>
						<div className="b-cookie-info__right">
							<div className="b-cookie-info__icon-set">
								{backdoorIcon && <BackdoorIcon className="b-cookie-info__icon" />}
								{swissPrivacyIcon && <SwissIcon className="b-cookie-info__icon" />}
								{gdprIcon && <GDPRIcon className="b-cookie-info__icon" />}
							</div>
						</div>
					</div>
					<div className="b-cookie-info__row">
						<div className="b-cookie-info__left">
							<div className="b-cookie-info__text" dangerouslySetInnerHTML={{
								__html: leftSideInfo.childMarkdownRemark.html,
							}}/>
							<div className="b-cookie-info__radio-holder">
								<span className="b-cookie-info__radio-label b-cookie-info__radio-label--blue"
									onClick={() => this.handleClickToFull()}>
									{cookieSwitcherFull}
								</span>
								<div className={classNames('b-cookie-info__radio', 
									{ 'b-cookie-info__radio--active': isSwitherActive})}
									onClick={() => this.handleClick()}
									></div>
								<span className="b-cookie-info__radio-label"
									onClick={() => this.handleClickToMin()}>
									{cookieSwitcherRight}
								</span>
							</div>
							<div className="b-cookie-info__radio-info">
								<span className={classNames('b-cookie-info__radio-info-label', { 'b-cookie-info__radio-info-label--active': isSwitherActive })}>
								{isSwitherActive ? cookieInfoOn : cookieInfoOff} 
							 </span>
								{` ${cookieInfo}`}
							</div>
						</div>
						<div className="b-cookie-info__right">
							<div className="b-cookie-info__text" dangerouslySetInnerHTML={{
								__html: rightSideCookie.childMarkdownRemark.html,
							}} />
						</div>
					</div>
				</div>	
			</section>
		)
	}
}
 
export default CookieInfo;