import React, { PureComponent } from 'react';

import './styles.scss'

class CareersDoubleTextBanner extends PureComponent {
	render() {
		const { 
			title,
          	subtitle,
			leftBlockText,
			rightBlockText } = this.props 
		return ( 
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-double-text-banner">
					<div className="b-double-text-banner__header">
						<div className="b-double-text-banner__header-lead t-label" dangerouslySetInnerHTML={{
							__html: subtitle,
						}}/>
						<h2 className="b-double-text-banner__header-title t-title" dangerouslySetInnerHTML={{
							__html: title,
						}} />
						<div className="b-double-text-banner__line">
						</div>
					</div>
					<div className="b-double-text-banner__info">
						<div className="b-double-text-banner__info-left t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
							__html: leftBlockText.childMarkdownRemark.html,
						}}>
						</div>
						<div className="b-double-text-banner__info-right t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
							__html: rightBlockText.childMarkdownRemark.html,
						}}>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
 
export default CareersDoubleTextBanner;