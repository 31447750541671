import React, { PureComponent } from 'react';

import './styles.scss'
class CareersValuesList extends PureComponent {
	render() {
		const { 
			firstValueTitle,
			firstValueDescription, 
			secondValueTitle,
			secondValueDescription, 
			thirdValueTitle,
			thirdValueDescription,
			fourthValueTitle,
			fourthValueDescription,
		} = this.props
		return ( 
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-values-list__overflow-scroll">
					<div className="b-values-list__overflow-container">
						<ul className="b-values-list">
							<li className="b-values-list__item t-text-block t-text-block--title-m-20 t-text-block--paragraph-m-16">
								<h4 className="b-values-list__item-title">{firstValueTitle}</h4>
								<p className="b-values-list__item-description">{firstValueDescription}</p>
							</li>
							<li className="b-values-list__item t-text-block t-text-block--title-m-20 t-text-block--paragraph-m-16">
								<h4 className="b-values-list__item-title">{secondValueTitle}</h4>
								<p className="b-values-list__item-description">{secondValueDescription}</p>
							</li>
							<li className="b-values-list__item t-text-block t-text-block--title-m-20 t-text-block--paragraph-m-16">
								<h4 className="b-values-list__item-title">{thirdValueTitle}</h4>
								<p className="b-values-list__item-description">{thirdValueDescription}</p>
							</li>
							<li className="b-values-list__item t-text-block t-text-block--title-m-20 t-text-block--paragraph-m-16">
								<h4 className="b-values-list__item-title">{fourthValueTitle}</h4>
								<p className="b-values-list__item-description">{fourthValueDescription}</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
		)
	}
}
 
export default CareersValuesList;