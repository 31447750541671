import React, { PureComponent } from 'react';
import iconRemoteUrl, { ReactComponent as IconRemote } from '../../static/images/icons/remotework.svg'
import iconFlexibleUrl, { ReactComponent as IconFlexible } from '../../static/images/icons/flexiblehours.svg'
import iconChillOutUrl, { ReactComponent as IconChillOut } from '../../static/images/icons/chilloutspace.svg'
import iconGrowthUrl, { ReactComponent as IconGrowth } from '../../static/images/icons/growthbudg.svg'
import iconKnowledgeUrl, { ReactComponent as IconKnowledge } from '../../static/images/icons/knowledgesharing.svg'
import iconSandwithesUrl, { ReactComponent as IconSandwithes } from '../../static/images/icons/sandwiches.svg'

import './styles.scss'
import LoadMoreButton from '../LoadMoreButton/LoadMoreButton';
import LearnMoreButton from '../LearnMoreButton/LearnMoreButton';

class IconsWithButton extends PureComponent {
	render() {
		const {
			buttonText,
			buttonLink,
			firstIconTitle,
			secondIconTitle,
			thirdIconTitle,
			fourthIconTitle,
			fifthIconTitle,
			sixthIconTitle
		} = this.props
		return (
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-benefits-icons">
					<ul className="b-benefits-icons__list">
						{firstIconTitle && <li className="b-benefits-icons__list-item">
							<IconRemote className="b-benefits-icons__list-item-icon b-benefits-icons__list-item-icon--big" />
							<span className="b-benefits-icons__list-item-header" dangerouslySetInnerHTML={{
								__html: firstIconTitle,
							}} />
						</li>}
						{secondIconTitle && <li className="b-benefits-icons__list-item">
							<IconFlexible className="b-benefits-icons__list-item-icon"/>
							<span className="b-benefits-icons__list-item-header" dangerouslySetInnerHTML={{
								__html: secondIconTitle,
							}} />
						</li>}
						{thirdIconTitle && <li className="b-benefits-icons__list-item">
							<IconChillOut className="b-benefits-icons__list-item-icon"/>
							<span className="b-benefits-icons__list-item-header" dangerouslySetInnerHTML={{
								__html: thirdIconTitle,
							}} />
						</li>}
						{fourthIconTitle && <li className="b-benefits-icons__list-item">
							<IconGrowth className="b-benefits-icons__list-item-icon"/>
							<span className="b-benefits-icons__list-item-header" dangerouslySetInnerHTML={{
								__html: fourthIconTitle,
							}} />
						</li>}
						{fifthIconTitle && <li className="b-benefits-icons__list-item">
							<IconKnowledge className="b-benefits-icons__list-item-icon"/>
							<span className="b-benefits-icons__list-item-header" dangerouslySetInnerHTML={{
								__html: fifthIconTitle,
							}} />
						</li>}
						{sixthIconTitle && <li className="b-benefits-icons__list-item">
							<IconSandwithes className="b-benefits-icons__list-item-icon"/>
							<span className="b-benefits-icons__list-item-header" dangerouslySetInnerHTML={{
								__html: sixthIconTitle,
							}} />
						</li>}
					</ul>
					<LearnMoreButton text={buttonText} link={buttonLink} className="b-benefits-icons__btn"/>
				</div>
			</section>
		)
	}
}

export default IconsWithButton;