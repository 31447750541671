import React, { Component } from 'react'

import './styles.scss'

class SearchButton extends Component {
  state = {}
  render() {
    return (
      <a href="#" className="b-button b-button--search">
        Subscribe
      </a>
    )
  }
}

export default SearchButton
