import React, { PureComponent } from 'react';

import './styles.scss'

class DoubleTextBanner extends PureComponent {
	render() {
		const { 
			title,
          	descriptionLong,
			leftSideText,
			rightSideText } = this.props 
		return ( 
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-legal-double-text-banner">
					<div className="b-legal-double-text-banner__header">
						<h2 className="b-legal-double-text-banner__header-title" dangerouslySetInnerHTML={{
							__html: title,
						}} />
						<div className="b-legal-double-text-banner__line">
						</div>
						<div className="b-legal-double-text-banner__description" dangerouslySetInnerHTML={{
							__html: descriptionLong.childMarkdownRemark.html,
						}}/>
					</div>
					<div className="b-legal-double-text-banner__info">
						<div className="b-legal-double-text-banner__info-left" dangerouslySetInnerHTML={{
							__html: leftSideText.childMarkdownRemark.html,
						}}>
						</div>
						<div className="b-legal-double-text-banner__info-right" dangerouslySetInnerHTML={{
							__html: rightSideText.childMarkdownRemark.html,
						}}>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
 
export default DoubleTextBanner;