import React, { PureComponent, Fragment } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import BlogCardInfo from '../BlogCardInfo/BlogCardInfo'
import BlogCardVideoInfo from '../BlogCardVideoInfo/BlogCardVideoInfo'
import VideoFilter from '../VideoFilter/VideoFilter'

import './styles.scss'

class BlogCard extends PureComponent {
  getCardCover() {
    if (
      this.props.data.featuredImage &&
      this.props.type !== 'text' &&
      this.props.type !== 'video'
    ) {
      return (
        <Img
          className="c-card__image"
          fixed={this.props.data.featuredImage.fixed}
        />
      )
    } else if (
      this.props.data.featuredVideoCover &&
      this.props.type == 'video'
    ) {
      return (
        <div>
          <Img
            className="c-card__poster"
            fixed={this.props.data.featuredVideoCover.fixed}
          />
          <VideoFilter className="c-card__filter" />
        </div>
      )
    }
  }

  render() {
    let { data, type, size } = this.props
    return (
      <div
        className={`c-card ${size !== undefined ? `c-card--${size}` : ''} ${
          type !== undefined ? `c-card--${type}` : ''
        }`}
      >
        {this.getCardCover()}

        {this.props.type === 'video' ? (
          <BlogCardVideoInfo data={data} type={type} size={size} />
        ) : (
          <BlogCardInfo data={data} type={type} size={size} />
        )}
      </div>
    )
  }
}

export default BlogCard
