import React, { PureComponent, Fragment } from 'react';
import { Link } from 'gatsby'
import classNames from "classnames"
import TextareaAutosize from "react-autosize-textarea"

import './styles.scss'

class ModalSend extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			emailFormatHasError: false,
			emailEmpty: false,
			firstNameEmpty: false,
			lastNameEmpty: false,
			messageEmpty: false,
			firstName: '',
			lastName: '',
			email: '',
			subject: '',
			message: '',
			company: '',
			formSended: false,
		}
	}

	handleInputChange({ target: { name, value } }) {
		this.setState({
			[name]: value,
			[`${name}FormatHasError`]: false,
			[`${name}Empty`]: false,
		});
	}

	validateEmailInput() {
		const emailPattern = /\S+@\S+\.\S+/;
		if (this.state.email.length > 0 && !emailPattern.test(this.state.email)) {
			this.setState({
				emailFormatHasError: true
			})
			return false
		} else if (this.state.email.length == 0) {
			this.setState({
				emailEmpty: true
			})
			return false
		}
		return true
	}

	validateFirstNameInput() {
		if (this.state.firstName.length == 0) {
			this.setState({
				firstNameEmpty: true
			})
			return false
		}
		return true
	}

	validateLastNameInput() {
		if (this.state.lastName.length == 0) {
			this.setState({
				lastNameEmpty: true
			})
			return false
		}
		return true
	}
	validateMessagetInput() {
		if (this.state.message.length == 0) {
			this.setState({
				messageEmpty: true
			})
			return false
		}
		return true
	}

	handleSubmit(e) {
		const {
			emailFormatHasError,
			emailEmpty,
			firstNameEmpty,
			lastNameEmpty,
			portfolioFormatHasError,
			isAgree
		} = this.state
		e.preventDefault()
		e.stopPropagation()
		this.validateEmailInput()
		this.validateFirstNameInput()
		this.validateLastNameInput()
		this.validateMessagetInput()
		if (this.validateEmailInput()
			&& this.validateFirstNameInput()
			&& this.validateLastNameInput()
			&& this.validateMessagetInput() ) {
			this.setState({
				formSended: true,
			})
		}
	}

	render() {
		const {
			subTitle,
			firstNamePlaceholder,
			lastNamePlaceholder,
			emailPlaceholder,
			phonePlaceholder,
			companyPlaceholder,
			submitButtonText,
			emptyFieldError,
			wrongEmailError,
			tankTitle,
			thankSubtitle,
			messagePlaceholder,
			subjectPlaceholder
		} = this.props

		const {
			firstName,
			lastName,
			email,
			company,
			emailFormatHasError,
			emailEmpty,
			firstNameEmpty,
			lastNameEmpty,
			messageEmpty,
			formSended,
			subject,
			message,
		} = this.state

		return (
			<section className="g-container g-container--double-pad">
				<div className="b-contact-form">
					{formSended
						? <div style={{ paddingTop: 50, paddingBottom: 50 }}>
						<h2 className="b-modal__thank-title">{tankTitle}</h2>
						<span className="b-modal__thank-subtitle">{thankSubtitle}</span>
					 </div>
					: <Fragment>
						<h2 className="b-contact-form__title">{subTitle}</h2>
						<form className="b-modal-apply__form" action="submit">
							<div className="b-modal-apply__form-col b-modal-apply__form-col--left">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{firstNameEmpty && emptyFieldError}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										type="text"
										className={
											classNames('b-modal__input-text b-modal-apply__form-input',
												{ 'b-modal__input-text--error': firstNameEmpty })
										}
										name="firstName"
										id="firstName"
										required />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': firstName.length > 0 })
										}
										htmlFor="firstName">
										{firstNamePlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal-apply__form-input')}
										type="text"
										name="company"
										autoComplete='organization'
										id="company" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': company.length > 0 })
										}
										htmlFor="company">
										{companyPlaceholder}
									</label>
								</div>
							</div>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--right">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{lastNameEmpty && emptyFieldError}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										className={
											classNames('b-modal__input-text b-modal-apply__form-input',
												{ 'b-modal__input-text--error': lastNameEmpty })
										}
										type="text"
										name="lastName"
										id="lastName"
										required />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': lastName.length > 0 })
										}
										htmlFor="lastName">
										{lastNamePlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{emailEmpty && emptyFieldError}
										{emailFormatHasError && wrongEmailError}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										className={
											classNames('b-modal__input-text b-modal-apply__form-input',
												{ 'b-modal__input-text--error': emailEmpty || emailFormatHasError })
										}
										type="email"
										name="email"
										autoComplete='email'
										id="email"
										required />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': email.length > 0 })
										}
										htmlFor="email">
										{emailPlaceholder}
									</label>
								</div>
							</div>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--full-width">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<TextareaAutosize
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal__input-text-area b-modal-apply__form-input')}
										name="subject"
										id="subject" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': subject.length > 0 })
										}
										htmlFor="subject">
										{subjectPlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{messageEmpty && emptyFieldError}
									</span>
									<TextareaAutosize
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal__input-text-area b-modal-apply__form-input')}
										name="message"
										required
										id="message" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': message.length > 0 })
										}
										htmlFor="message">
										{messagePlaceholder}
									</label>
								</div>
							</div>
								<div className="b-modal-apply__form-col b-modal-apply__form-col--full-width b-contact-form__btn-holder">
								<button
									className="b-learn-more b-contact-form__btn"
									onClick={e => this.handleSubmit(e)}
									type="submit"
								>
									{submitButtonText}</button>
							</div>
						</form>
					</Fragment>}
				</div>
			</section>
		)
	}
}

export default ModalSend;