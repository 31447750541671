
import React, { PureComponent, Fragment } from 'react';
import { Link } from 'gatsby'

import "./styles.scss"

import Modal from '../Modal/Modal'
import ModalSend from '../ModalSend/ModalSend';
import CtaButton from '../CtaButton/CtaButton';

class SendBtn extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
			modalShouldBeRender: false,
		}
		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);
	}

	handleShow(e) {
		this.setState({ showModal: true });
		document.querySelector('body').classList.add('body--fixed')
	}

	handleHide() {
		this.setState({ showModal: false });
		document.querySelector('body').classList.remove('body--fixed')
	}

	render() {
		const { onClick, text, type } = this.props
		const modal = this.state.showModal ? (
			<Modal color="white">
				<button className="modal__hide" onClick={this.handleHide}></button>
				<ModalSend modal={this.props.modal} close={() => this.handleHide()} />
			</Modal>
		) : null;
		return (
			<Fragment>
				{type === "cta" 
					? <button className="b-button b-button--main-cta b-button--wide b-button--red" onClick={this.handleShow}>
					{text}
				</button>
					: <button className="b-send-btn" onClick={this.handleShow}>
					{text}
				</button> }
				
				{modal}
			</Fragment>
		)
	}
}

export default SendBtn