import React from 'react'
import LegalInformation from '../containers/LegalInformation'
import Blog from '../containers/Blog'
import SEO from '../components/SEO/SEO'
import CareersMeetUs from '../containers/Careers/MeetUs'
import CareersPositions from '../containers/Careers/Positions'
import CareersLocations from '../containers/Careers/Locations'
import TextBanner from '../components/TextBanner/TextBanner'
import CareersJumbo from '../components/CareersJumbo/CareersJumbo'
import CareersNav from '../components/CareersNav/CareersNav'
import CareersSwissMapArea from '../components/CareersSwissMapArea/CareersSwissMapArea'
import CareersComponentOurCompany from '../components/CareersOurCompany/CareersOurCompany'
import CareersValuesList from '../components/CareersValuesList/CareersValuesList'
import CareersManifesto from '../components/CareersManifesto/CareersManifesto'
import CareersDoubleTextBanner from '../components/CareersDoubleTextBanner/CareersDoubleTextBanner'
import TextBannerSmall from '../components/TextBannerSmall/TextBannerSmall'
import JumboTextLeft from '../components/JumboTextLeft/JumboTextLeft'
import IconsWithButton from '../components/IconsWithButton/IconsWithButton'
import LocationHeader from '../components/LocationHeader/LocationHeader'
import Location from '../components/Location/Location'
import BenefitsHeader from '../components/BenefitsHeader/BenefitsHeader'
import Perks from '../components/Perks/Perks'
import OpenPositions from '../components/OpenPositions/OpenPositions'
import LocationSpoiler from '../components/LocationSpoiler/LocationSpoiler'
import CareersTalents from '../components/CareersTalents/CareersTalents'
import Jumbo from '../components/LegalComponents/Jumbo/Jumbo';
import LegalInformationBlock from '../components/LegalComponents/LegalInformationBlock/LegalInformationBlock';
import LegalTwoSide from '../components/LegalComponents/LegalTwoSide/LegalTwoSide';
import CookieTable from '../components/LegalComponents/CookieTable/CookieTable';
import CookieInfo from '../components/LegalComponents/CookieInfo/CookieInfo';
import DoubleTextBanner from '../components/LegalComponents/DoubleTextBanner/DoubleTextBanner';
import ContactForm from '../components/LegalComponents/ContactForm/ContactForm';

const ComponentNotFound = ({ __typename }) => (
	<p>
		Component <strong>{__typename}</strong> Not Found
	</p>
)

// here you have to map __typename to actual component
const components = {
	ContentfulPageLegalInformation: LegalInformation,
	ContentfulPageBlogPage: Blog,
	ContentfulSeo: SEO,
	ContentfulPageCareersMeetUs: CareersMeetUs,
	ContentfulPageCareersPositions: CareersPositions,
	ContentfulPageCareersLocations: CareersLocations,
	ContentfulCareersComponentTextBanner: TextBanner,
	ContentfulCareersComponentJumbo: CareersJumbo,
	ContentfulNav: CareersNav,
	ContentfulCareersComponentsSwissMapArea: CareersSwissMapArea,
	ContentfulCareersComponentOurCompany: CareersComponentOurCompany,
	ContentfulCareersComponentValuesList: CareersValuesList,
	ContentfulCareersComponentManifesto: CareersManifesto,
	ContentfulCareersComponentDoubleTextBanner: CareersDoubleTextBanner,
	ContentfulCareersComponentTextBannerSmallWithButton: TextBannerSmall,
	ContentfulCareersComponentJumboTextLeft: JumboTextLeft,
	ContentfulCareersComponentIconsWithButton: IconsWithButton,
	ContentfulCareersComponentLocationHeader: LocationHeader,
	ContentfulCareersComponentLocation: Location,
	ContentfulCareersComponentBenefitsHeader: BenefitsHeader,
	ContentfulCareersComponentsPerks: Perks,
	ContentfulCareersComponentOpenPositions: OpenPositions,
	ContentfulCareersComponentLocationsSpoiler: LocationSpoiler,
	ContentfulCareersPositions: CareersPositions,
	ContentfulCareersComponentTalents: CareersTalents,
	ContentfulLegalComponentsJumbo: Jumbo,
	ContentfulLegalComponentsLegalInformation: LegalInformationBlock,
	ContentfulLegalComponentsLegalTwoSideBlock: LegalTwoSide,
	ContentfulLegalComponentsCookieTable: CookieTable,
	ContentfulLegalComponentsCookieInfo: CookieInfo,
	ContentfulLegalComponentsDoubleTextBanner: DoubleTextBanner,
	ContentfulLegalComponentsContactForm: ContactForm,
}

export default props => {
	const SelectedComponent = components[props.__typename]
		? components[props.__typename]
		: ComponentNotFound

	return <SelectedComponent {...props} />
}
