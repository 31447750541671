import React, { PureComponent } from 'react';

import './styles.scss'

class LegalInformationBlock extends PureComponent {
	state = {}
	render() { 
		const { title, descriptionLong, leftTextBlock, firstAddress, secondAddress} = this.props
		return (
			<section className="g-container g-container--double-pad">
				<div className="b-legal-information-block">
					<div className="b-legal-information-block__head">
						<h2 className="b-legal-information-block__title">
							{title}
						</h2>
						<div className="b-legal-information-block__line"></div>
						<div className="b-legal-information-block__description" dangerouslySetInnerHTML={{
							__html: descriptionLong.childMarkdownRemark.html,
						}} />
					</div>
					<div className="b-legal-information-block__info">
						<div className="b-legal-information-block__info-block b-legal-information-block__info-block--half"
							dangerouslySetInnerHTML={{
								__html: leftTextBlock.childMarkdownRemark.html,
							}} 
						/>
						<div className="b-legal-information-block__info-block b-legal-information-block__info-block--address b-legal-information-block__info-block--pl"
							dangerouslySetInnerHTML={{
								__html: firstAddress.childMarkdownRemark.html,
							}} 
						/>
						<div className="b-legal-information-block__info-block b-legal-information-block__info-block--address"> 
							<div dangerouslySetInnerHTML={{
								__html: secondAddress.childMarkdownRemark.html,
							}} />
						</div>
					</div>
				</div>
			</section>
		)
	}
}
 
export default LegalInformationBlock;