import React, { PureComponent } from 'react'

import Slider from 'react-slick'

import BlogCard from '../BlogCard/BlogCard'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.scss'

import arrowUrl, {
  ReactComponent as Arrow,
} from '../../static/images/icons/feat_arr.svg'

let SampleNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      {' '}
      <Arrow />
    </div>
  )
}

let SamplePrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      {' '}
      <Arrow />
    </div>
  )
}

class Featured extends PureComponent {
  render() {
    const { posts } = this.props
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      swipeToSlide: 1,
      centerPadding: 50,
      centerMode: true,
      adaptiveHeight: true,
      arrow: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }
    return (
      <div className="b-featured">
        <div className="b-featured__header">
          <h2 className="b-featured__title">Featured</h2>
          <span className="b-featured__subtitle">
            Things that it’s good to know about us. You will feel better ;)
          </span>
        </div>
        <div className="b-featured__slider">
          {posts.length > 0 && (
            <Slider {...settings}>
              {posts.map((post, key) => {
                return (
                  <div key={key} className="b-featured__slide">
                    <BlogCard
                      size="onethird"
                      type={post.postType}
                      data={post}
                    />
                  </div>
                )
              })}
            </Slider>
          )}
        </div>
      </div>
    )
  }
}

export default Featured
