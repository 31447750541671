import React, { PureComponent } from 'react';
import _ from 'underscore'
import Select from 'react-select';
import { navigate } from "gatsby"

import CommonContext from '../ContextProvider/CommonContext'

import './styles.scss'

const CareersTalentsConsumer = (props) => (
	<CommonContext.Consumer>
		{context => (
			<CareersTalents 
				context={context} 
				{...props}
			/>)
		}
	</CommonContext.Consumer>
)

class CareersTalents extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { 
			selectedOption: '',
			renderDepartments: []
		}
	}

	componentDidMount() {
		const departments = this.props.departments
		const positions = this.props.positions

		let readyDepatments = positions.map(position => {
			let sortedDepartments = []
			departments.map(department => {
				if (position.node.department.title === department.node.title) {
					sortedDepartments.push(department.node.title)
				}
			})
			return (sortedDepartments)
		})

		readyDepatments = _.flatten(readyDepatments)
		readyDepatments = _.uniq(readyDepatments)
		readyDepatments.splice(0, 0, 'All')

		readyDepatments = readyDepatments.map(item => {
			return {value: item, label: item}
		})

		this.setState({
			renderDepartments: readyDepatments
		})
	}

	handleChange = (selectedOption) => {
		this.props.context.state.changeDepartmentFilter(selectedOption.value)
		navigate("/careers/open-positions#b-position")
	}

	render() { 
		const { selectedOption, renderDepartments } = this.state;
		const { title, selectTitle, placeholder } = this.props;
		return ( 
			<section style={{display: 'none'}} className="g-container g-container--double-pad">
				<div className="b-careers-talents">
					<h2 className="b-careers-talents__title">
						{title}
					</h2>
					<div className="b-careers-talents__select-block">
						<span className="b-careers-talents__select-block-title">{selectTitle}</span>
						<Select
							className="b-input b-input-select b-careers-talents-select"
							classNamePrefix="b-input-select"
							placeholder={placeholder}
							name="form-field-name"
							value={selectedOption}
							onChange={this.handleChange}
							options={renderDepartments}
							isSearchable={false}
						/>
					</div>
				</div>
			</section>
		)
	}
}
 
export default CareersTalentsConsumer;