import React, { PureComponent } from 'react'
import { Link } from 'gatsby'

import { format, isToday, isYesterday } from 'date-fns'

import './styles.scss'

class BlogNewsFeed extends PureComponent {
  getSortedNewsByDate(array) {
    return array.sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date)
    })
  }
  getNewsDate(date) {
    if (isToday(date)) {
      return `Today`
    } else if (isYesterday(date)) {
      return `Yesterday`
    } else {
      return format(date, 'MMM DD YYYY')
    }
  }
  render() {
    let { news } = this.props
    return (
      <div className="c-blog-newsfeed">
        <h2 className="c-blog-newsfeed__title">News Room</h2>
        <ul className="c-blog-newsfeed__list">
          {this.getSortedNewsByDate(news).map((news, key) => {
            return (
              <li key={key} className="c-blog-newsfeed__item">
                <span className="c-blog-newsfeed__date">
                  {this.getNewsDate(news.node.date)}
                </span>
                {news.node.title}
              </li>
            )
          })}
        </ul>
        <Link to="#" className="c-blog-newsfeed__link">
          Visit News Room
        </Link>
      </div>
    )
  }
}

export default BlogNewsFeed
