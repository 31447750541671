import React, { PureComponent } from 'react'

import _ from 'lodash'

import BlogCardPoster from '../components/BlogCardPoster/BlogCardPoster'
import BlogCard from '../components/BlogCard/BlogCard'
import CtaButton from '../components/CtaButton/CtaButton'
import BlogSubscribe from '../components/BlogSubscribe/BlogSubscribe'
import Featured from '../components/Featured/Featured'
import BlogNewsFeed from '../components/BlogNewsFeed/BlogNewsFeed'
import LoadMoreButton from '../components/LoadMoreButton/LoadMoreButton'
import SubHeader from '../components/SubHeader/SubHeader'

import './blog.scss'

class BlogPostsPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isActive: 'All',
      articles: [],
      amountRenderedPatterns: 2,
    }

    if (props.categories[0].node.title !== 'All') {
      props.categories.unshift({ node: { title: 'All' } })
    }
  }

  componentDidMount() {
    const { edges } = this.props.posts
    let articlesToState = [...edges]

    articlesToState = this.getArticlesToRender(articlesToState)

    this.setState({
      articles: articlesToState,
    })
  }

  getArticlesToRender(arr) {
    let sizes = [3, 2, 1]

    arr.splice(0, 0, 'news')
    arr.splice(6, 0, 'subscribe')

    let result = _.chunk(arr, _.sum(sizes)).map(articles =>
      sizes.map(size => {
        if (articles.length > 0) {
          return articles.splice(0, size)
        }
      })
    )

    result = result.map(item => item.filter(x => x))

    return result
  }

  filterPostByCategory(category) {
    const { edges } = this.props.posts
    let result = []

    let articlesToFilter = [...edges]

    if (category === 'All') {
      result = articlesToFilter
    } else {
      articlesToFilter.map(item => {
        if (item.node.categoryone.title === category) {
          return result.push(item)
        }
      })
    }

    result = this.getArticlesToRender(result)

    this.setState({
      articles: result,
    })
  }

  getSizeOfItem(rowNumber) {
    let itemWidth = ''
    if (rowNumber == 0) {
      itemWidth = 'onethird'
    } else if (rowNumber == 1) {
      itemWidth = 'onehalf'
    } else if (rowNumber == 2) {
      itemWidth = 'full'
    }
    return itemWidth
  }

  chooseCategory(name, e) {
    e.preventDefault()
    
    this.setState({
      isActive: name,
    })
    this.filterPostByCategory(name)
  }

  loadMore() {
    this.setState({
      amountRenderedPatterns: this.state.amountRenderedPatterns + 1,
    })
  }

  render() {
    let {
      posts,
      categories,
      news,
      title,
      topTitle,
      topPost,
      featuredPosts,
    } = this.props
    let { isActive, articles, amountRenderedPatterns } = this.state
    return (
      <section className="s-blog-posts">
        <SubHeader title={title} topTitle={topTitle} />
        <div className="g-container g-container--poster g-container--line">
          <BlogCardPoster data={topPost} />
        </div>
        <div className="g-container g-container--double-pad">
          <div className="b-blog-header">
            <div className="b-blog-header-subscribe">
              <span className="b-blog-header-subscribe__title">
                Subscribe if you wan’t be up todate with our product.
              </span>
              <CtaButton text="SUBSCRIBE NOW" type="cta-blog-nav" />
            </div>
            <ul className="b-blog-header-nav">
              {categories.map((item, key) => {
                return (
                  <li key={key} className="b-blog-header-nav__item">
                    <a
                      href="#"
                      className={`b-blog-header-nav__link ${
                        isActive === item.node.title
                          ? 'b-blog-header-nav__link--active'
                          : ''
                      }`}
                      onClick={e => {
                        window.plausible(`Click Link with text: ${item.node.title}`, {props: {page: window.location.pathname}})
                        this.chooseCategory(item.node.title, e)
                      }}
                    >
                      <span className="b-blog-header-nav__link-inner">
                        {item.node.title}
                      </span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        {articles.map((pattern, patKey) => {
          if (patKey < amountRenderedPatterns) {
            return (
              <div key={patKey}>
                {pattern.map((row, rowKey) => {
                  return (
                    <div
                      key={rowKey}
                      className={`g-container ${
                        row[0] === 'subscribe'
                          ? 'g-container--blog-subscribe'
                        : 'g-container--double-pad g-container--mb-40'
                      } `}
                    >
                      {row.map((item, itemKey) => {
                        if (item === 'news') {
                          return <BlogNewsFeed key={itemKey} news={news} />
                        } else if (item === 'subscribe') {
                          return <BlogSubscribe key={itemKey} />
                        } else {
                          return (
                            <BlogCard
                              key={itemKey}
                              data={item.node}
                              size={this.getSizeOfItem(rowKey)}
                              type={item.node.type}
                            />
                          )
                        }
                      })}
                    </div>
                  )
                })}
              </div>
            )
          }
        })}

        <div className="g-container g-container--load-more">
          {articles.length > amountRenderedPatterns ? (
            <LoadMoreButton onClick={() => this.loadMore()} />
          ) : (
            ''
          )}
        </div>

        <div className="g-container g-container--blog-featured">
          <Featured posts={featuredPosts} />
        </div>
      </section>
    )
  }
}

export default BlogPostsPage
