import React, { PureComponent } from 'react';
import { Link } from 'gatsby'

import CommonContext from '../ContextProvider/CommonContext'

import './styles.scss'


const PositionItem = ({ title, department, region, active, slug }) => {
	return (
		<li className="b-positions-listing__item">
			<Link to={`/${slug}`} className={`b-positions-listing__link`}>
				<span className="b-positions-listing__link-title">{title}</span>
				<span className="b-positions-listing__link-department">{department}</span>
				<span className="b-positions-listing__link-region" dangerouslySetInnerHTML={{
					__html: region,
				}}></span>
			</Link>
		</li>
	)
}

const OpenPositionsContextWrapper = (props) => (
	<CommonContext.Consumer>
		{(context) => (
			<OpenPositions
				context={context}
				{...props} />

		)}
	</CommonContext.Consumer>
)

class OpenPositions extends PureComponent {
	constructor (props) {
		super(props);
		this.state = {
			active: "All",
			positionsToRender: props.positions
		}

		if (props.departments[0].node.title !== 'All') {
			props.departments.unshift({ node: { title: 'All' } })
		}
	}

	getAmountOfPositions() {
		const { positions } = this.props;
		if (positions.length < 2) {
			return (
				<div className="b-position-header__amount">
					<span className="b-position-header__amount-number">{positions.length}</span> Open Position
				</div>
			)
		} else {
			return (
				<div className="b-position-header__amount">
					<span className="b-position-header__amount-number">{positions.length}</span>  Open Positions
				</div>
			)
		}
	}

	filterPositionsByDepartment(department) {
		const edges = this.props.positions
		let result = []

		let positionsToFilter = [...edges]
		if (department === 'All') {
			result = positionsToFilter
		} else {
			positionsToFilter.map(item => {
				if (item.node.department.title === department) {
					return result.push(item)
				}
			})
		}

		this.setState({
			positionsToRender: result,
		})
	}

	componentDidMount() {
		const { context } = this.props;

		this.setState({
			active: context.state.activeDepartmentFilter
		})
		this.filterPositionsByDepartment(context.state.activeDepartmentFilter)
	}

	departmentShouldRendered(name) {
		const match = this.props.positions.map((item) => {
			if (name === item.node.department.title) {
				return true
			}
		})
		if ( match.indexOf(true) >= 0) {
			return true
		} else {
			return false
		}
	}

	handleSelectChange = ({ target: { value } }) => {
		const { context } = this.props;

		context.state.changeDepartmentFilter(value)
		this.filterPositionsByDepartment(value)
	}

	handleDepartmentClick = (item, e) => {
		e.preventDefault();
		const { context } = this.props;

		context.state.changeDepartmentFilter(item.node.title)
		this.filterPositionsByDepartment(item.node.title)
	}

	render() {
		const {
			title,
			departments,
			descriptionLong,
			context
		} = this.props
		const { positionsToRender } = this.state
		return (
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div id="b-position"></div>
				<div className="b-positions">
					<div className="b-positions__header b-position-header">
						{this.getAmountOfPositions()}
						<div className="b-position-header__title">
							{title}
						</div>
						<div className="b-position-header__cta b-position-header-cta" />
					</div>
					<div className="b-positions-mobile-filter">
						<span htmlFor="mobile-department-select" className="b-positions-mobile-filter__label" >{context.state.activeDepartmentFilter}</span>
						<select id="mobile-department-select"
							name="mobile-department-select"
							className="b-positions-mobile-filter__select"
							onChange={this.handleSelectChange}>
							{departments.map((item, key) => {
								if (this.departmentShouldRendered(item.node.title) || item.node.title === "All") {
									return (
										<option key={key} value={item.node.title}>{item.node.title}</option>
									)
								}
							})}
						</select>
					</div>
					<div className="b-positions__body">
						<div className="b-positions__departments">
							<ul className="b-positions-departments__list">
								{departments.map((item, key) => {
									if (this.departmentShouldRendered(item.node.title) || item.node.title === "All") {
										return (
											<li key={key} className="b-positions-departments__item">
												<a href="#"
													className={`b-positions-departments__link ${context.state.activeDepartmentFilter == item.node.title
														? 'b-positions-departments__link--active' : ''}`}
													onClick={e => this.handleDepartmentClick(item, e)}>
													{item.node.title}
												</a>
											</li>
										)
									}
								})}
							</ul>
						</div>
						<div className="b-positions__listing">
							<ul className="b-positions-listing__list">
								{positionsToRender.map((item, key) => {
									return(
										<PositionItem key={key}
											title={item.node.title}
											department={item.node.department.title}
											region={item.node.region}
											slug={item.node.slug}
										/>
									)
								})}
							</ul>
							<div className="b-positions-listing__description" dangerouslySetInnerHTML={{
								__html: descriptionLong.childMarkdownRemark.html,
							}}>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default OpenPositionsContextWrapper;