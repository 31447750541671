import React, { PureComponent } from 'react';

import iconRemoteUrl, { ReactComponent as IconRemote } from '../../static/images/icons/remotework.svg'
import iconFlexibleUrl, { ReactComponent as IconFlexible } from '../../static/images/icons/flexiblehours.svg'
import iconChillOutUrl, { ReactComponent as IconChillOut } from '../../static/images/icons/chilloutspace.svg'
import iconGrowthUrl, { ReactComponent as IconGrowth } from '../../static/images/icons/growthbudg.svg'
import iconKnowledgeUrl, { ReactComponent as IconKnowledge } from '../../static/images/icons/knowledgesharing.svg'
import iconSandwithesUrl, { ReactComponent as IconSandwithes } from '../../static/images/icons/sandwiches.svg'

import './styles.scss'

class Perks extends PureComponent {
	render() { 
		const  {
			firstPerk,
          	secondPerk,
         	thirdPerk,
          	fourthPerk,
          	fifthPerk,
          	sixthPerk 
		} = this.props
		return ( 
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-perks">
				
					<ul className="b-perks__list">
						{firstPerk && <li className="b-perks__list-item">
							<IconRemote className="b-perks__list-item-icon b-perks__list-item-icon--big" />
							<div className="b-perks__list-item-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
								__html: firstPerk.childMarkdownRemark.html,
							}}/>
						</li>}
						{secondPerk && <li className="b-perks__list-item">
							<IconFlexible className="b-perks__list-item-icon" />
							<div className="b-perks__list-item-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
								__html: secondPerk.childMarkdownRemark.html,
							}}/>
						</li>}
						{thirdPerk && <li className="b-perks__list-item">
							<IconChillOut className="b-perks__list-item-icon" />
							<div className="b-perks__list-item-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
								__html: thirdPerk.childMarkdownRemark.html,
							}}/>
						</li>}
						{fourthPerk && <li className="b-perks__list-item">
							<IconGrowth className="b-perks__list-item-icon" />
							<div className="b-perks__list-item-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
								__html: fourthPerk.childMarkdownRemark.html,
							}}/>
						</li>}
						{fifthPerk && <li className="b-perks__list-item">
							<IconKnowledge className="b-perks__list-item-icon" />
							<div className="b-perks__list-item-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
								__html: fifthPerk.childMarkdownRemark.html,
							}}/>
						</li>}
						{sixthPerk && <li className="b-perks__list-item">
							<IconSandwithes className="b-perks__list-item-icon" />
							<div className="b-perks__list-item-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
								__html: sixthPerk.childMarkdownRemark.html,
							}}/>
						</li>}
					</ul>
				</div>
			</section>
		 )
	}
}
 
export default Perks;