import React, { PureComponent } from 'react';

import './styles.scss'
import CtaButton from '../CtaButton/CtaButton';

class TextBannerSmall extends PureComponent {
	render() {
		const {
			title,
			description,
			buttonTetxt,
			buttonLink } = this.props
		return ( 
			<section className="g-container g-container--fw-tablet">
				<div className="b-text-banner">
					<h2 className="b-text-banner__title t-title-middle t-title-middle--dark" dangerouslySetInnerHTML={{
						__html: title,
					}} />
					<div className="b-text-banner__line"></div>
					<p className="b-text-banner__description t-paragraph t-paragraph--gray" dangerouslySetInnerHTML={{
						__html: description,
					}} />
					<CtaButton className="b-button b-button--main-cta b-button--regular b-button--red b-text-banner__btn" type="small" link={buttonLink} text={buttonTetxt} />
				</div>
			</section>
		 )
	}
}
 
export default TextBannerSmall;