import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames'

import Img from 'gatsby-image';

import './styles.scss'

class Jumbo extends PureComponent {
	state = {}

	render() {
		const { title, subTitle, descriptionLong, choseBackground, imageBackground, videoBackground, bottomPadding } = this.props
		const jumboInner = () => {
			return (
				<div className="b-legal-jumbo__inner">
					<span className="b-legal-jumbo__subtitle t-label t-label--white t-label--semibold ">{subTitle}</span>
					<h1 className="b-legal-jumbo__title">{title}</h1>
					<div className="b-legal-jumbo__description" dangerouslySetInnerHTML={{
						__html: descriptionLong.childMarkdownRemark.html,
					}} />
				</div>
			)
		}
		const jumboImageBg = (imageBackground) => {
			return (
				<div className="b-legal-jumbo__image-wrapper">
					<Img className="b-legal-jumbo__image"
						fluid={imageBackground.fluid} />
				</div>
			)
		}
		const jumboVideoBg = (imageBackground, videoBackground) => {
			return (
				<Fragment>
					<div className="b-legal-jumbo__image-wrapper">
						<Img className="b-legal-jumbo__image"
							fluid={imageBackground.fluid}
							ref={(video) => this.video = video} />
					</div>
					<video
						className="b-legal-jumbo__video-bg"
						width="640" height="360"
						autoPlay
						muted
						loop
						playsInline>
						<source src={videoBackground.file.url} type="video/mp4" />
					</video>
				</Fragment>
				
			)
		}
		return ( 
			<section className="g-container g-container--no-pad g-container--fw">
				<div className={classNames("b-legal-jumbo", {
					'b-legal-jumbo--bottom-mg': bottomPadding
				})}>{choseBackground[0] ==='color' ? null :
						choseBackground[0] === 'image'
							? jumboImageBg(imageBackground)
							: jumboVideoBg(imageBackground, videoBackground)}
					{jumboInner()}
				</div>
			</section>
		)
	}
}
 
export default Jumbo;