import React, { PureComponent, Fragment } from 'react';
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.scss'

import arrowUrl, {
	ReactComponent as Arrow,
} from '../../static/images/icons/feat_arr.svg'

import swissFlagUrl, {
	ReactComponent as SwissFlag,
} from '../../static/images/icons/flag_swiss.svg'
import rbFlagUrl, {
	ReactComponent as RbFlag,
} from '../../static/images/icons/flag_rb.svg'
import conditionUrl, {
	ReactComponent as ConditionIcon,
} from '../../static/images/icons/workingconditions.svg'
import spaceUrl, {
	ReactComponent as SpaceIcon,
} from '../../static/images/icons/officespace.svg'
import pointerUrl, {
	ReactComponent as PointerIcon,
} from '../../static/images/icons/adress.svg'

import Slider from 'react-slick'

const SampleNextArrow = props => {
	const { className, style, onClick } = props
	return (
		<div className={className} onClick={onClick}>
			{' '}
			<Arrow />
		</div>
	)
}

const SamplePrevArrow = props => {
	const { className, style, onClick } = props
	return (
		<div className={className} onClick={onClick}>
			&nbsp;
			<Arrow />
		</div>
	)
}

const LocationSliderHeader = ({ title, description, tagLine }) => {
	return (
		<div className="b-location-slider-header">
			<div className="b-location-slider-header__tagline">{tagLine}</div>
			<h2 className="b-location-slider-header__title" dangerouslySetInnerHTML={{
				__html: title,
			}} />
			<div className="b-location-slider-header__line"></div>
			<div className="b-location-slider-header__description">
				{description}
			</div>
		</div>
	)
}

const Location = ({ title,
	description,
	tagLine,
	slides,
	flag,
	firstTextBlock,
	secondTextBlock,
	thirdTextBlock,
	phoneNumber,
	addressFirstLine,
	addressSecondLine,
	linkToGoogle
}) => {
	const chosePadding = () => {
		if (typeof window !== 'undefined' && window.innerWidth < 480) {
			return 10
		} else if (typeof window !== 'undefined' && window.innerWidth < 780) {
			return 50
		} else {
			return 150
		}
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		swipeToSlide: 1,
		centerPadding: chosePadding(),
		centerMode: true,
		adaptiveHeight: true,
		arrow: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	}

	if (flag[0] !== "Switzerland")
		return null;

	return (
		<Fragment >
			<section className="g-container g-container--no-pr g-container--slider">
				<div className="section-anchor" id={flag[0]}></div>
				<div className="b-location">
					<div className="b-location-slider">
						{slides && slides.length > 0 && (
							<Slider {...settings}>
								{slides.map((slide, key) => {
									return (
										<div key={key} className="b-location-slider__wrapper">
											<div className="b-location-slider__image-wrapper">
												<Img
													className="b-location-slider__image"
													fluid={slide.fluid}
												/>
											</div>
											{key === 0 && title && <LocationSliderHeader title={title} description={description} tagLine={tagLine} />}
										</div>
									)
								})}
							</Slider>
						)}
					</div>
				</div>
			</section>
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-location__info">
					<div className="b-location__info-block">
						{flag[0] == "Switzerland"
							? <SwissFlag className="b-location__info-flag" />
							: <RbFlag className="b-location__info-flag b-location__info-flag--rb" />}
						<div className="b-location__info-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
							__html: firstTextBlock.childMarkdownRemark.html,
						}}/>

					</div>
					<div className="b-location__info-block">

						<ConditionIcon className="b-location__info-flag" />
						<div className="b-location__info-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
							__html: secondTextBlock.childMarkdownRemark.html,
						}}/>

					</div>
					<div className="b-location__info-block">

						<SpaceIcon className="b-location__info-flag" />
						<div className="b-location__info-text t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
							__html: thirdTextBlock.childMarkdownRemark.html,
						}}/>

					</div>
				</div>
				<div className="b-location-address">
					<div className="b-location-address__col">

					</div>
					<div className="b-location-address__col b-location-address__col--double">
						<div className="b-location-address__phone">
							<a href={linkToGoogle}
								target="blank"
								className="b-location-address__pointer--link">
								<PointerIcon className="b-location-address__pointer" />
							</a>
							<div>
								{tagLine} <br />
								<a href={`mailto:${phoneNumber}`}
									className="b-location-address__phone-link"
								>{phoneNumber}</a>
							</div>
						</div>
						<a href={linkToGoogle} target="blank" className="b-location-address__address">
							<span dangerouslySetInnerHTML={{
								__html: addressFirstLine
							}} />
							<br/>
							<span dangerouslySetInnerHTML={{
								__html: addressSecondLine
							}} />
						</a>
						<a href={`mailto:${phoneNumber}`}
							className="b-location-address__phone-link b-location-address__phone-link--mobile"
						>{phoneNumber}</a>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

export default Location;