import React, { PureComponent } from 'react'
import Img from 'gatsby-image'
import { format } from 'date-fns'

import BlogCardInfo from '../BlogCardInfo/BlogCardInfo'

import './styles.scss'

class BlogCardPoster extends PureComponent {
  render() {
    let { data } = this.props
    // TODO: Remove keylang when we will have contextAPI
    return (
      <div className="c-poster">
        {/* <Img
          className="c-poster__image"
          sizes={data.featuredImage.sizes}
        /> */}
        <div className="c-poster__info">
          <BlogCardInfo data={data} type="noborder" />
        </div>
      </div>
    )
  }
}

export default BlogCardPoster
