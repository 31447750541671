import React, { PureComponent } from 'react';

import './styles.scss'

class LegalTwoSide extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {  }
	}
	render() {
		const { rightSideBlock, leftSideBlock} = this.props
		return (
			<section className="g-container g-container--double-pad">
				<div className="b-legal-two-side">
					<div className="b-legal-two-side__left" dangerouslySetInnerHTML={{
						__html: leftSideBlock.childMarkdownRemark.html,
					}}/>
					<div className="b-legal-two-side__right" dangerouslySetInnerHTML={{
						__html: rightSideBlock.childMarkdownRemark.html,
					}} />
				</div>
			</section>
		)
	}
}

export default LegalTwoSide;