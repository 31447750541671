import React, { PureComponent } from 'react';

import CareersJumbo from '../../components/CareersJumbo/CareersJumbo';
import CareersNav from '../../components/CareersNav/CareersNav';
import TextBanner from '../../components/TextBanner/TextBanner';

import './careers.scss';

class CreersMeetUs extends PureComponent {
	state = {}
	render() {
		// const { jumbotron, nav, careersComponents } = this.props 
		return (
			<section className="s-careers">
				{/* <div className="g-container g-container--no-pad">
					<CareersJumbo props={jumbotron}/>
				</div>
				<div className="g-container g-container--double-pad"> 
					<CareersNav nav={nav} />
					{careersComponents && careersComponents.map((components, key) => {
						return (<TextBanner key={key} title={components.title} description={components.description.childMarkdownRemark.html} />)
					})}
				</div> */}
			</section> 
		)
	} 
}
 
export default CreersMeetUs;