import React, { PureComponent } from 'react';
import CareersJumbo from '../../components/CareersJumbo/CareersJumbo';
import CareersNav from '../../components/CareersNav/CareersNav';

class CareersLocations extends PureComponent {
	state = {}
	render() {
		const props = this.props
		return (
			<section className="s-careers">
				{/* <div className="g-container g-container--no-pad">
					<CareersJumbo props={props.jumbotron} />
				</div>
				<div className="g-container g-container--double-pad">
					<CareersNav nav={props.nav} />
				</div> */}
			</section>
		)
	}
}

export default CareersLocations;