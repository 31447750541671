import React, { PureComponent } from 'react';
import Img from "gatsby-image"

import './styles.scss'
import LearnMoreButton from '../LearnMoreButton/LearnMoreButton';

class LocationHeader extends PureComponent {
	render() {
		const {
			topList,
			titleLong,
			descriptionLong,
			leftTextBlock,
			rightTextBlock,
			buttonLink,
			buttonText,
			locationHeaderImage
		} = this.props
		return (
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-location-header">
					<div className="b-location-header__left">
						<ul className="b-location-header__list">
							{topList.map((item, key) => {
								return (
									<li key={key}
										className="b-location-header__list-item t-paragraph t-paragraph--blue t-paragraph--desctop-15 t-paragraph--semibold">
										{/* TODO: rework with https://stackoverflow.com/questions/1183903/regex-using-javascript-to-return-just-numbers */}
											<span className="b-location-header__list-item-number"> {item.match(/\d+/g).join([])}</span>
											<span className="b-location-header__list-item-text"> {item.replace(/[0-9]/g, '')}</span>
									</li>
								)
							})}
						</ul>
						<h2 className="b-location-header__title t-title t-title--dark" dangerouslySetInnerHTML={{
							__html: titleLong.childMarkdownRemark.html,
						}} />
						<div className="b-location-header__description t-paragraph t-paragraph--dark" dangerouslySetInnerHTML={{
							__html: descriptionLong.childMarkdownRemark.html,
						}} />
						<div className="b-location-header__img-wrapper">
							<Img fluid={locationHeaderImage.fluid}
								className="b-location-header__img" />
						</div>
					</div>
					<div className="b-location-header__right"></div>
					<div className="b-location-header__info">
						<div className="b-location-header__info-left t-paragraph t-paragraph--gray t-paragraph--desctop-15" dangerouslySetInnerHTML={{
							__html: leftTextBlock.childMarkdownRemark.html,
						}} />
						<div className="b-location-header__info-right t-paragraph t-paragraph--gray t-paragraph--desctop-15" dangerouslySetInnerHTML={{
							__html: rightTextBlock.childMarkdownRemark.html,
						}} />
					</div>
					<LearnMoreButton className="b-location-header__btn" text={buttonText} link={buttonLink} />
				</div>
			</section>
		)
	}
}

export default LocationHeader;