import React, { PureComponent } from 'react';
import Img from 'gatsby-image'
import './styles.scss'

class CareersJumbo extends PureComponent {
	render() {
		const { featuredImage, tagLine, title, description } = this.props
		return (
			<div className="g-container g-container--no-pad g-container--fw-tablet">
				<div className="b-jumbo">
					{featuredImage && <Img
						className="b-jumbo__image-wrapper"
						fluid={featuredImage.fluid}
					/>}
					<div className="b-jumbo__header">
						<span className="b-jumbo__tagline t-label t-label--white">{tagLine}</span>
						<h1 className="b-jumbo__title t-title t-title--white">{title}</h1>
						<div className="b-jumbo__line"></div>
						<span className="b-jumbo__subtitle t-label t-label--white">{description}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default CareersJumbo;