import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class ReadingTime extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      readTime: 0,
    }
  }

  componentWillMount() {
    this.updateReadingTime(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.updateReadingTime(nextProps)
  }

  updateReadingTime(props) {
    const words = this.countWords(props.text)
    const readTime = Math.round(words / props.wordsPerMinute)
    this.setState({ readTime })
  }

  countWords(text) {
    return text.split(/\s+/).length
  }

  render() {
    const { props } = this
    const { readTime } = this.state
    // TODO: lol
    // const minutes = readTime === 1 ? 'minute' : 'minutes'
    const minutes = readTime === 1 ? 'min' : 'min'

    return (
      <span className={props.className}>
        {readTime} {minutes}
      </span>
    )
  }
}

ReadingTime.propTypes = {
  wordsPerMinute: PropTypes.number,
  textColor: PropTypes.string,
  piggies: PropTypes.string,
}
ReadingTime.defaultProps = {
  wordsPerMinute: 200,
  textColor: 'red',
  piggies: 'weeee',
}
