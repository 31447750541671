import React, { PureComponent } from 'react';
import Img from 'gatsby-image'

import './styles.scss'

import CtaButton from '../CtaButton/CtaButton';
import SendBtn from '../SendBtn/SendBtn';

class JumboTextLeft extends PureComponent {
	render() {
		const { title, description, featuredImage, buttonText, modal } = this.props
		return ( 
			<section className="g-container g-container--no-pad g-container--fw-tablet">
				{/* <div className="b-jumbo-text-left">
					{featuredImage && <Img
						outerWrapperClassName="b-jumbo-text-left__image-wrapper"
						className="b-jumbo-text-left__image-wrapper"
						sizes={featuredImage.sizes}
					/>}
					<div className="b-jumbo-text-left__header">
						<h1 className="b-jumbo-text-left__title t-title t-title--white">{title}</h1>
						<div className="b-jumbo-text-left__line"></div>
						<h2 className="b-jumbo-text-left__subtitle t-label t-label--white">{description}</h2>
						<SendBtn text={buttonText} modal={modal} type="cta"/>
					</div>
				</div> */}
			</section>
		 )
	}
}
 
export default JumboTextLeft;