import React, { Component } from 'react'
import { Link } from 'gatsby'

import SubscribeButton from '../SubscribeButton/SubscribeButton'

import './styles.scss'
import logoUrl, { ReactComponent as Logo } from '../../static/images/logo.svg'

class SubHeader extends Component {
  render() {
    let { title, topTitle } = this.props
    return (
      <section className="s-subheader">
        <div className="g-container g-container--subheader">
          <Link to="/">
            <Logo className="b-subheader__logo" />
          </Link>
          <hgroup className="b-subheader__group">
            <span className="b-subheader__lead">{topTitle}</span>
            <h1 className="b-subheader__title">{title}</h1>
          </hgroup>
          <SubscribeButton />
        </div>
      </section>
    )
  }
}

export default SubHeader
