import React, { PureComponent } from 'react'

import CtaButton from '../CtaButton/CtaButton'

import './styles.scss'

import Background from '../../static/images/subscribe_bg.svg'

class BlogSubscribe extends PureComponent {
  state = {}
  render() {
    return (
      <div
        className="b-blog-subscribe"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <span className="b-blog-subscribe__label">Newsletter</span>
        <h2 className="b-blog-subscribe__title">
          Subscribe if you wan’t be up todate with our product.
        </h2>
        <CtaButton text="Subscribe now" type="cta-blog-subscribe" />
        <div className="b-blog-subscribe__line" />
        <p className="b-blog-subscribe__paragraph">
          Get awesome content in your inbox every week. Just like over 3 million
          others do.
        </p>
        <p className="b-blog-subscribe__paragraph">We won’t spam you!</p>
      </div>
    )
  }
}

export default BlogSubscribe
