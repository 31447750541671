import React, { PureComponent } from 'react';
import { Link } from 'gatsby'
import CommonContext from '../ContextProvider/CommonContext'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import classNames from 'classnames'

import './styles.scss'
const CareersNavContextWrapper = (props) => (
	<CommonContext.Consumer>
		{(context) => (
			<CareersNav
				context={context}
				{...props} />

		)}
	</CommonContext.Consumer>
)
class CareersNav extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			activeNavItem: "",
			showMenuItem: false,
		}
	}

	componentDidMount() {
		let slug = window.location.pathname.slice(1)
		if (slug.endsWith('/')){
			slug = slug.slice(0, -1)
		}
		this.setState({
			activeNavItem: slug
		})

		setTimeout(() => {
			if (document.querySelector('#activeNavWraper')) {
				let el = document.querySelector('#activeNavWraper')
				this.nav.scrollTo({
					left: (el.offsetLeft - 20) - (this.wrapper.offsetWidth - el.offsetWidth)/2,
					top: 0,
				})

			}
			this.setState({
				showMenuItem: true
			})
		}, 1);
	}
	setSlug(slug) {
		this.setState({
			activeNavItem: slug,
		})
	}

	render() {
		const { pages, context, scrollToMenu } = this.props
		const { activeNavItem, showMenuItem } = this.state
		return (
			<div className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div id="b-careers-nav"></div>
					<div className="b-careers-nav__overflow-wrapper"
					ref={(wrapper) => this.wrapper = wrapper}>
						<ul className="b-careers-nav" ref={(nav) => { this.nav = nav }}>
							{pages.map((item) => {
								if (item.slug === "careers/locations")
									return null
								return (
									<li key={item.id}
										className={classNames("b-careers-nav__item", {
											'b-careers-nav__item--visible': showMenuItem
										})}
										// TODO: Create mgethod
										id={`${item.slug}` === activeNavItem && activeNavItem.replace(/\//g, '') ? 'activeNavWraper' : ''}
										>
										<Link
											to={`/${item.slug}${scrollToMenu ? '#b-careers-nav' : ''}`}
											className={`b-careers-nav__link ${`${item.slug}` === activeNavItem && activeNavItem.replace(/\//g, '') ? "b-careers-nav__link--active" : ''}`}
											onClick={(item) => { this.setSlug(item.slug, item.id)} }
										>
											<span className="b-careers-nav__link-title">{item.nameInNav}</span>
											<span className="b-careers-nav__link-subtitle" dangerouslySetInnerHTML={{
												__html: item.subTitleInNav,
											}} />
										</Link>
									</li>
								)
							})}
					</ul>
				</div>
			</div>
		)
	}
}

export default CareersNavContextWrapper;