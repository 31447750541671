import React, { PureComponent } from 'react';

import './styles.scss'

class CookieTable extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {  }
	}
	render() { 
		const { 
			firstColTitle,
         	secondColTitle,
          	thirdColTitile,
          	rows } = this.props
		return ( 
			<section className="g-container g-container--double-pad">
				<div className="b-cookie-table">
					<table className="b-cookie-table__table">
						<thead className="b-cookie-table__table-head">
							<tr className="b-cookie-table__table-head-row">
								<th className="b-cookie-table__table-head-col">{firstColTitle}</th>
								<th className="b-cookie-table__table-head-col">{secondColTitle}</th>
								<th className="b-cookie-table__table-head-col b-cookie-table__table-head-col--long">{thirdColTitile}</th>
							</tr>
						</thead>
						<tbody className="b-cookie-table__table-head-body">
							{rows.map((row, key) => {
								return (
									<tr key={key} className="b-cookie-table__table-row">
										<td className="b-cookie-table__table-col">{row.firstColumn}</td>
										<td className="b-cookie-table__table-col">{row.secondColumn}</td>
										<td className="b-cookie-table__table-col"><div dangerouslySetInnerHTML={{
											__html: row.thirdColumn.childMarkdownRemark.html,
										}}/></td>
									</tr>
								)
							})}
							
						</tbody>
					</table>
				</div>
			</section>
		)
	}
}
 
export default CookieTable;