import React from 'react'

const VideoFilter = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <filter id="duotone">
        {/* <feColorMatrix type="matrix" result="grayscale"
                values="1 0 0 0 0
                        1 0 0 0 0
                        1 0 0 0 0
                        0 0 0 1 0" /> */}
        <feComponentTransfer colorInterpolationFilters="sRGB" result="duotone">
          <feFuncR type="table" tableValues="0.05098039215 0.4431372549" />
          <feFuncG type="table" tableValues="0.34117647058 0.93725490196" />
          <feFuncB type="table" tableValues="0.59607843137 0.85490196078" />
          <feFuncA type="table" tableValues="0 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
      </filter>
    </svg>
  )
}

export default VideoFilter
