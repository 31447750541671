import React, { PureComponent } from 'react'

import './styles.scss'
class LoadMoreButton extends PureComponent {
  render() {
    const { onClick } = this.props
    return (
      <button className="b-load-more" onClick={onClick}>
        Load More
      </button>
    )
  }
}

export default LoadMoreButton
