import React, { PureComponent, Fragment } from 'react';
import { Link } from 'gatsby'
import classNames from "classnames"
import TextareaAutosize from "react-autosize-textarea"
import { isValidNumber } from 'libphonenumber-js'

import galaUrl, {
	ReactComponent as GalaIcon,
} from '../../static/images/icons/gala.svg'

import './styles.scss'

class ModalSend extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			emailFormatHasError: false,
			emailEmpty: false,
			firstNameEmpty: false,
			lastNameEmpty: false,
			portfolioFormatHasError: false,
			isAgree: false,
			isAgreeError: false,
			firstName: '',
			lastName: '',
			email: '',
			location: '',
			jobTitle: '',
			phone: '',
			portfolio: '',
			whyApply: '',
			formSended: false,
			uploadCv: {
				name: '',
				size: '',
				type: '',
				validationError: '',
			}
		}
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
			[`${e.target.name}FormatHasError`]: false,
			[`${e.target.name}Empty`]: false,
		});
	}

	handleFileInputChange(e) {
		if (e.target.files[0]) {
			this.setState({
				uploadCv: {
					name: e.target.files[0].name,
					size: e.target.files[0].size / 1048576,
					type: e.target.files[0].name.split(".").pop().toLowerCase(),
					validationError: '',
				}
			}, () => this.validateFileInput())
		}
	}

	handleCheckBoxClick() {
		this.setState({
			isAgree: !this.state.isAgree,
			isAgreeError: false,
		})
	}

	validateAgreement() {
		if(!this.state.isAgree) {
			this.setState({
				isAgreeError: true
			})
		}
	}

	validateFileInput() {
		const { size, type, name } = this.state.uploadCv
		if (name.length > 0 && size > 41) {
			this.setState({ uploadCv: {
				validationError: 'size',
				name: '',
				size: '',
				type: '',
			}})
			return false
		}
		if (name.length == 0) {
			this.setState({ uploadCv: {
				validationError: 'empty',
				name: '',
				size: '',
				type: '',
			} })
			return false
		}
		if (name.length > 0 && !(type === 'pdf' || type === 'doc' || type === 'docx' || type === 'txt')) {
			this.setState({ uploadCv: {
				validationError: 'type',
				name: '',
				size: '',
				type: '',
			} })
			return false
		}
		return true
	}

	validateEmailInput() {
		const emailPattern = /\S+@\S+\.\S+/;
		if (this.state.email.length > 0 && !emailPattern.test(this.state.email)) {
			this.setState({
				emailFormatHasError: true
			})
			return false
		} else if (this.state.email.length == 0) {
			this.setState({
				emailEmpty: true
			})
			return false
		}
		return true
	}

	validatePortfolioUrl() {
		const urlPattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
		if (this.state.portfolio.length > 0) {
			if (!urlPattern.test(this.state.portfolio)) {
				this.setState({
					portfolioFormatHasError: true
				})
				return false
			}
		}
		return true
	}

	validateFirstNameInput() {
		if (this.state.firstName.length == 0) {
			this.setState({
				firstNameEmpty: true
			})
			return false
		}
		return true
	}

	validateLastNameInput() {
		if (this.state.lastName.length == 0) {
			this.setState({
				lastNameEmpty: true
			})
			return false
		}
		return true
	}

	removeFile(e) {
		e.preventDefault()
		e.stopPropagation()
		this.setState({ uploadCv: {
			validationError: '',
			name: '',
			size: '',
			type: '',
		} })
	}

	handleSubmit(e) {
		const {
			emailFormatHasError,
			emailEmpty,
			firstNameEmpty,
			lastNameEmpty,
			portfolioFormatHasError,
			isAgree
		} = this.state
		e.preventDefault()
		e.stopPropagation()
		this.validateEmailInput()
		this.validateFirstNameInput()
		this.validateLastNameInput()
		this.validateFileInput()
		this.validatePortfolioUrl()
		this.validateAgreement()
		if (isAgree
			&& this.validateEmailInput()
			&& this.validateFirstNameInput()
			&& this.validateLastNameInput()
			&& this.validateFileInput()
			&& this.validatePortfolioUrl()) {
			this.setState({
				formSended: true,
			})
			setTimeout(() => {
				this.props.close()
			}, 3000);
		}
	}

	render() {
		const {
			title,
			subTitle,
			firstNamePlaceholder,
			lastNamePlaceholder,
			emailPlaceholder,
			phonePlaceholder,
			locationPlaceholder,
			jobTitlePlaceholder,
			portfolioPlaceholder,
			uploadCvText,
			agreementCheckboxText,
			linkToPolicyText,
			linkToPolicy,
			submitButtonText,
			emptyFieldError,
			wrongEmailError,
			wrongPortfolioUrl,
			tankTitle,
			thankSubtitle,
			whyApplyPlaceholder,
		} = this.props.modal

		const {
			firstName,
			lastName,
			email,
			phone,
			portfolio,
			jobTitle,
			location,
			uploadCv,
			isAgree,
			emailFormatHasError,
			emailEmpty,
			firstNameEmpty,
			lastNameEmpty,
			portfolioFormatHasError,
			isAgreeError,
			formSended,
			whyApply
		} = this.state

		const fileInputLabel = uploadCv.name ? (
			<Fragment>
				<div
					onClick={(e) => this.removeFile(e)}
					className="b-modal__remove-file"
					role="presentation"
					aria-label="remove file"
					></div>
				<span className="b-modal__file-label-text">
					{uploadCv.name}
				</span>
			</Fragment>
			)
			: (<Fragment>
				<span className="b-modal__file-label-text">
					* {uploadCvText}
				</span>
				<span className="b-modal__file-label-text--condition">DOC / PDF. 5Mb max.</span>
			</Fragment>)

		return (
			<section className="b-modal-apply">
				{formSended
					? <div>
						<h2 className="b-modal__thank-title">{tankTitle}</h2>
						<span className="b-modal__thank-subtitle">{thankSubtitle}</span>
					</div>
					: <div className="b-modal-apply__wrapper">
						<span className="b-modal-apply__subtitle">{subTitle}</span>
						<h2 className="b-modal-apply__title">{title}</h2>
						<form
							className="b-modal-apply__form"
							action="https://formbucket.com/f/buk_hFPHZa04KqCs8a2ugMWQNNEb"
							encType="multipart/form-data"
						>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--left">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{firstNameEmpty && emptyFieldError}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										type="text"
										className={
											classNames('b-modal__input-text b-modal-apply__form-input',
												{ 'b-modal__input-text--error': firstNameEmpty })
										}
										name="firstName"
										id="firstName"
										required />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': firstName.length > 0 })
										}
										htmlFor="firstName">
										{firstNamePlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{emailEmpty && emptyFieldError}
										{emailFormatHasError && wrongEmailError}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										className={
											classNames('b-modal__input-text b-modal-apply__form-input',
												{ 'b-modal__input-text--error': emailEmpty || emailFormatHasError })
										}
										type="email"
										name="email"
										autoComplete='email'
										id="email"
										required />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': email.length > 0 })
										}
										htmlFor="email">
										{emailPlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal-apply__form-input')}
										type="text"
										name="location"
										id="location" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': location.length > 0 })
										}
										htmlFor="location">
										{locationPlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{portfolioFormatHasError && wrongPortfolioUrl}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal-apply__form-input',
											{ 'b-modal__input-text--error': portfolioFormatHasError })
										}
										type="text"
										name="portfolio"
										id="portfolio" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': portfolio.length > 0 })
										}
										htmlFor="portfolio">
										{portfolioPlaceholder}
									</label>
								</div>
							</div>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--right">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{lastNameEmpty && emptyFieldError}
									</span>
									<input
										onChange={e => this.handleInputChange(e)}
										className={
											classNames('b-modal__input-text b-modal-apply__form-input',
												{ 'b-modal__input-text--error': lastNameEmpty })
										}
										type="text"
										name="lastName"
										id="lastName"
										required />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': lastName.length > 0 })
										}
										htmlFor="lastName">
										{lastNamePlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										onChange={e => this.handleInputChange(e)}
										className="b-modal__input-text b-modal__input-text--phone b-modal-apply__form-input"
										type="tel"
										name="phone"
										autoComplete='tel'
										value={phone}
										id="phone" />

									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': phone.length > 0 })
										}
										htmlFor="phone">
										{phonePlaceholder}
									</label>
									<span className="b-modal__input-plus">+</span>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal-apply__form-input')}
										type="text"
										name="jobTitle"
										id="jobTitle" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': jobTitle.length > 0 })
										}
										htmlFor="jobTitle">
										{jobTitlePlaceholder}
									</label>
								</div>
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<label
										className={classNames('b-modal__file-label b-modal-apply__form-label',
											{ 'b-modal__file-label--loaded': uploadCv.name },
											{ 'b-modal__file-label--error': uploadCv.validationError.length > 0 }
										)}
										htmlFor="uploadCv">
										{fileInputLabel}
									</label>
									<input type="file"
										onChange={e => this.handleFileInputChange(e)}
										className={classNames('b-modal__input-file b-modal-apply__form-input')}
										name="uploadCv"
										id="uploadCv"
										accept="application/msword, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									/>
									<span className="b-modal__input-file-errors">
										{uploadCv.validationError === 'empty' && 'requared field'}
										{uploadCv.validationError === 'size' && 'file to big'}
									</span>
								</div>
							</div>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--full-width">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<TextareaAutosize
										onChange={e => this.handleInputChange(e)}
										className={classNames('b-modal__input-text b-modal__input-text-area b-modal-apply__form-input')}
										type="text"
										name="whyApply"
										id="whyApply" />
									<label
										className={classNames('b-modal__label b-modal-apply__form-label',
											{ 'b-modal__label--active': whyApply.length > 0 })
										}
										htmlFor="whyApply">
										{whyApplyPlaceholder}
									</label>
								</div>
							</div>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--full-width">
								<legend className="b-modal__legend">
									<input id="b-modal__checkbox" className="b-modal__checkbox" type="checkbox" />
									<span className={classNames('b-modal__checkbox-holder',
										{ 'b-modal__checkbox-holder--error': isAgreeError }
									)}>{isAgree && <GalaIcon className="b-modal__checkbox-gala" />}</span>
									<label htmlFor="b-modal__checkbox"
										className={classNames('b-modal__checkbox-label')}
										onClick={e => this.handleCheckBoxClick()}
									>
										{agreementCheckboxText}
									</label>
									<Link to={`/${linkToPolicy.slug}`}
										target="_blank"
										className="b-modal__policy-link"
									>{linkToPolicyText}</Link>
								</legend>
								<button
									className="b-button b-button--cta"
									/** onClick={e => this.handleSubmit(e)} **/
									type="submit"
								>
									{submitButtonText}</button>
							</div>
						</form>
					</div>}
			</section>
		)
	}
}

export default ModalSend;