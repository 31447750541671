import React, { PureComponent } from 'react';
import classNames from 'classnames'

import './styles.scss'

class BenefitsHeader extends PureComponent {
	render() {
		const { 
			title,
          	description,
          	mainBenefitsTitle,
		  	mainBemefits,
			removeBorderLine 
		} = this.props 
		return ( 
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className={classNames("b-benefits-header", {
					'b-benefits-header--no-border': removeBorderLine
				})}>
					<div className="b-benefits-header__col">
						{title && <h2 className="b-benefits-header__title t-title" dangerouslySetInnerHTML={{
							__html: title
						}}/>}
						{description && <div className="b-benefits-header__description t-paragraph t-paragraph--dark" dangerouslySetInnerHTML={{
							__html: description
						}}/>}
					</div>
					<div className="b-benefits-header__col"> 
						<span className="b-benefits-header__benefits-title" >
							{mainBenefitsTitle}
						</span> <br/>
						<ul className="b-benefits-header__benefits-list">
							{mainBemefits && mainBemefits.map((item, key) => {
								return (
									<li key={key} className="b-benefits-header__benefits-list-item">
										{item}
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</section>
		 )
	}
}
 
export default BenefitsHeader;