import React, { PureComponent } from 'react';

class CareersPositions extends PureComponent {
	state = {}
	render() {
		const props = this.props
		return (
			<section className="s-careers">
				<div className="g-container g-container--no-pad">
					ololo
				</div>
			</section>
		)
	}
}

export default CareersPositions;