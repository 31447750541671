import React from 'react'
import { graphql } from 'gatsby'
import ConnectContentComponent from '../utils/connectContentComponent'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO';

export default props => {
	const data = props.data
	const components = props.data.page.components

	return (
		<Layout {...props}>
			<SEO
				title={data.page.metaTitle}

				description={data.page.metaDescription}
				ogDescription={data.page.metaOgDescription}
				twitterDescription={data.page.metaTwitterDescription}

				cover={data.page.metaImage && data.page.metaImage.file.url}
				ogCover={data.page.metaOgImage && data.page.metaOgImage.file.url}
				twitterCover={data.page.metaTwitterImage && data.page.metaTwitterImage.file.url}

				slug={data.page.slug}
				slugs={props.pageContext.slugs}
				lang={props.pageContext.langKey}

				fbappid={data.globalConfig.fbappid}
				twitterUser={data.globalConfig.twitterUser}
				location={props.location}
			/>

			{components &&
				components.map((component, key) => {
					switch (component.__typename) {
						case 'ContentfulPageBlogPage':
							return (
								<ConnectxContentComponent
									key={key}
									posts={props.data.posts}
									categories={props.data.categories.edges}
									news={props.data.news.edges}
									{...component} >
								</ConnectxContentComponent>
							)
							break;
						case 'ContentfulCareersComponentOpenPositions':
							return (
								<ConnectContentComponent
									key={key}
									positions={props.data.positions.edges}
									departments={props.data.departments.edges}
									modal={props.data.keepMeModal.edges[0].node}
									{...component}
								/>
							)
							break;
						case 'ContentfulCareersComponentTalents':
							return (
								<ConnectContentComponent
									key={key}
									positions={props.data.positions.edges}
									departments={props.data.departments.edges}
									{...component}
								/>
							)
							break;
						case 'ContentfulCareersComponentJumboTextLeft':
							return (
								<ConnectContentComponent
									key={key}
									modal={props.data.sendmodal.edges[0].node}
									{...component}
								/>
							)
							break;
						default:
							return <ConnectContentComponent key={key} {...component} />
					}
				})}
		</Layout>
	)
}

export const pageQuery = graphql`
	query pageQuery($id: String!, $locale: String!) {
		sitePage {
			context {
				slugs
			}
		}
		site {
			siteMetadata {
				languages {
					defaultLangKey
					langs
				}
			}
		}

		mainNav: contentfulNav(
			name: { eq: "main-top-bar-nav" }
			node_locale: { eq: $locale }
		) {
			pages {
				slug
				nameInNav
				id

			}
		}
		mainFooterLight: contentfulFooterLight(
			name: { eq: "footer-light" }
			node_locale: { eq: "en-US" }
		) {
			title
			description
            rightsReservedLink
			ctaButton {
				title
				color
				size
				visible
				modal {
					internal {
						type
					}
					name
					title
					description
					tagLine
					emailPlaceholder
					agreementText
					linkToPage {
						slug
						title
					}
					submitButtonText
					emailErrorMessage
					tanksTitle
					thanksDescription
				}
			}
		}
		mainFooterLightPreAlpha: contentfulFooterLight(
			name: { eq: "footer-light-pre-alpha" }
			node_locale: { eq: "en-US" }
		) {
			title
			description
			showOnSpecificPageslug
			ctaButton {
				title
			}
		}

		headerLight:  allContentfulHeaderLight(filter: {
			name: {eq: "header-light"}
			node_locale: { eq: "en-US" }
		}) {
			edges {
				node {
					name
					ctaButton {
						title
						color
						size
						visible
						modal {
							internal {
								type
							}
							name
							title
							description
							tagLine
							emailPlaceholder
							agreementText
							linkToPage {
								slug
								title
							}
							submitButtonText
							emailErrorMessage
							tanksTitle
							thanksDescription
						}
					}
				}
			}
		}
		mainNavLight: contentfulNav(
			name: { eq: "main-top-bar-nav-light" }
			node_locale: { eq: $locale }
		) {
			pages {
				slug
				nameInNav
				id
			}
		}
		keepMeModal: allContentfulCareersPositionsKeepMeModal(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					internal {
						type
					}
					title
					subTitle
					firstNamePlaceholder
					lastNamePlaceholder
					emailPlaceholder
					categoresInputPlaceholder
					agreementCheckboxText
					linkToPolicyText
					linkToPolicy {
						slug
					}
					submitButtonText
					emptyFieldError
					wrongEmailError
					tankTitle
					thankSubtitle
				}
			}
		}

		careersNav: contentfulNav(
			name: { eq: "careers-nav" }
			node_locale: { eq: $locale }
		) {
			scrollToMenu
			pages {
				slug
				nameInNav
				id
				subTitleInNav

			}
		}

		categories: allContentfulCategory(
			filter: {node_locale: { eq: $locale } })
		{
			edges {
				node {
					title
					node_locale
				}
			}
		}
		news: allContentfulMediaNews(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					title
					date
					node_locale
				}
			}
		}
		posts: allContentfulPost(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					id
					node_locale
					title {
						title
					}
					authorone {
						name
					}
					categoryone {
						title
					}
					postType
					description
					createdAt
					excerpt {
						childMarkdownRemark {
							html
						}
					}
					body {
						body
					}
					# featuredVideo {
					#   file {
					#     url
					#     details {
					#       size
					#       image {
					#         width
					#       }
					#     }
					#   }
					# }
					# featuredVideoCover {
					#   id
					#   file {
					#     url
					#     fileName
					#     contentType
					#   }
					#   fixed(width: 1225) {
					#     base64
					#     width
					#     height
					#     src
					#     srcSet
					#     srcWebp
					#     srcSetWebp
					#   }
					# }
					# featuredImage {
					#   id
					#   file {
					#     url
					#     fileName
					#     contentType
					#   }
					#   fixed(width: 1225) {
					#     base64
					#     width
					#     height
					#     src
					#     srcSet
					#     srcWebp
					#     srcSetWebp
					#   }
					# }
					slug
				}
			}
		}
		positions: allContentfulCareersPositions(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					title
					department {
						title
					}
					region
					slug
				}
			}
		}
		globalConfig: contentfulGlobalConfig {
			id
			footer
			header
			fbappid
			twitterUser
		}
		departments: allContentfulCareersPositionsCategorys(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					title
				}
			}
		}
		sendmodal: allContentfulCareersPositionSendUsModal(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					internal {
						type
					}
					title
					subTitle
					firstNamePlaceholder
					lastNamePlaceholder
					emailPlaceholder
					phonePlaceholder
					portfolioPlaceholder
					uploadCvText
					agreementCheckboxText
					linkToPolicyText
					locationPlaceholder
					jobTitlePlaceholder
					whyApplyPlaceholder
					linkToPolicy {
						slug
					}
					submitButtonText
					emptyFieldError
					wrongEmailError
					wrongPortfolioUrl
					tankTitle
					thankSubtitle
				}
			}
		}
		page: contentfulPage(id: { eq: $id }) {
			slug
			title
			metaTitle
			metaDescription
			metaOgDescription
			metaTwitterDescription
			metaImage {
				file {
					url
				}
			}
			metaOgImage {
				file {
					url
				}
			}
			metaTwitterImage {
				file {
					url
				}
			}
			id
			node_locale
			components {
				__typename
				... on ContentfulLegalComponentsJumbo {
					title
					subTitle
					videoBackground {
						file {
							url
							details {
								size
								image {
									width
								}
							}
						}
					}
					imageBackground {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					choseBackground
					bottomPadding
					descriptionLong {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulLegalComponentsLegalInformation {
					title
					descriptionLong {
						childMarkdownRemark {
							html
						}
					}
					leftTextBlock {
						childMarkdownRemark {
							html
						}
					}
					firstAddress {
						childMarkdownRemark {
							html
						}
					}
					secondAddress {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulLegalComponentsLegalTwoSideBlock {
					leftSideBlock {
						childMarkdownRemark {
							html
						}
					}
					rightSideBlock {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulLegalComponentsCookieTable {
					firstColTitle
					secondColTitle
					thirdColTitile
					rows {
						firstColumn
						secondColumn
						thirdColumn {
							childMarkdownRemark {
								html
							}
						}
					}
				}
				... on ContentfulLegalComponentsCookieInfo {
					backdoorIcon
					swissPrivacyIcon
					gdprIcon
					leftSideInfo {
						childMarkdownRemark {
							html
						}
					}
					rightSideCookie {
						childMarkdownRemark {
							html
						}
					}
					cookieSwitcherFull
					cookieSwitcherRight
					cookieInfoOn
					cookieInfoOff
					cookieInfo
				}
				... on ContentfulLegalComponentsDoubleTextBanner {
					title
					descriptionLong {
						childMarkdownRemark {
							html
						}
					}
					leftSideText {
						childMarkdownRemark {
							html
						}
					}
					rightSideText {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulLegalComponentsContactForm {
					subTitle
					firstNamePlaceholder
					lastNamePlaceholder
					emailPlaceholder
					companyPlaceholder
					subjectPlaceholder
					messagePlaceholder
					submitButtonText
					emptyFieldError
					wrongEmailError
					tankTitle
					thankSubtitle
				}
				... on ContentfulNav {
					scrollToMenu
					pages {
						slug
						nameInNav
						id
						subTitleInNav
					}
				}
				... on ContentfulCareersComponentJumbo {
					title
					description
					tagLine
					featuredImage {
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
				}
				... on ContentfulCareersComponentTextBanner {
					heading
					subtitleLong {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulCareersComponentValuesList {
					firstValueTitle
					firstValueDescription
					secondValueTitle
					secondValueDescription
					thirdValueTitle
					thirdValueDescription
					fourthValueTitle
					fourthValueDescription
				}
				... on ContentfulCareersComponentOurCompany {
					firstOfficeTitle
					firstOfficeDescription
					secondOfficeTitle
					secondOfficeDescription
					thirdOfficeTittle
					thirdOfficeDescription
					companyDescription {
						childMarkdownRemark {
							html
						}
					}
					buttonText
					buttonLink
				}
				... on ContentfulCareersComponentTextBannerSmallWithButton {
					title
					description
					buttonTetxt
					buttonLink
				}
				... on ContentfulCareersComponentTalents {
					title
					selectTitle
					placeholder
				}
				... on ContentfulCareersComponentJumboTextLeft {
					title
					description
					featuredImage {
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					buttonText
				}
				... on ContentfulCareersComponentIconsWithButton {
					buttonText
					buttonLink
					firstIconTitle
					secondIconTitle
					thirdIconTitle
					fourthIconTitle
					fifthIconTitle
					sixthIconTitle
				}
				... on ContentfulCareersComponentManifesto {
					iconsTitle
					info {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulCareersComponentDoubleTextBanner {
					title
					subtitle
					leftBlockText {
						childMarkdownRemark {
							html
						}
					}
					rightBlockText {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulCareersComponentsSwissMapArea {
					titleLong {
						childMarkdownRemark {
							html
						}
					}
					subtitleLong {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulCareersComponentLocationHeader {
					topList
					titleLong {
						childMarkdownRemark {
							html
						}
					}
					locationHeaderImage {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					descriptionLong {
						childMarkdownRemark {
							html
						}
					}
					leftTextBlock {
						childMarkdownRemark {
							html
						}
					}
					rightTextBlock {
						childMarkdownRemark {
							html
						}
					}
					buttonLink
					buttonText
				}
				... on ContentfulCareersComponentLocation {
					title
					description
					tagLine
					slides {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					flag
					firstTextBlock {
						childMarkdownRemark {
							html
						}
					}
					secondTextBlock {
						childMarkdownRemark {
							html
						}
					}
					thirdTextBlock {
						childMarkdownRemark {
							html
						}
					}
					phoneNumber
					addressFirstLine
					addressSecondLine
					linkToGoogle
				}
				... on ContentfulCareersComponentBenefitsHeader {
					title
					description
					removeBorderLine
					mainBenefitsTitle
					mainBemefits
				}
				... on ContentfulCareersComponentsPerks {
					firstPerk {
						childMarkdownRemark {
							html
						}
					}
					secondPerk {
						childMarkdownRemark {
							html
						}
					}
					thirdPerk {
						childMarkdownRemark {
							html
						}
					}
					fourthPerk {
						childMarkdownRemark {
							html
						}
					}
					fifthPerk {
						childMarkdownRemark {
							html
						}
					}
					sixthPerk {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulCareersComponentOpenPositions {
					title
					ctaTitle
					ctaButtonText
					descriptionLong {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulCareersComponentLocationsSpoiler {
					firstTitle
					firstDescription
					firstImage {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					secondTitle
					secondDescription
					secondImage {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
                    thirdTitle
                    thirdDescription
                    thirdImage {
                        id
                        file {
                            url
                            fileName
                            contentType
                        }
                        fluid {
                            base64
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                        }
                    }
				}
				... on ContentfulPageBlogPage {
					title
					topTitle
					topPost {
						title {
							title
						}
						authorone {
							name
						}
						categoryone {
							title
						}
						description
						createdAt
						node_locale
						body {
							body
						}
						featuredImage {
							id
							file {
								url
								fileName
								contentType
							}
							fixed(width: 1225) {
								base64
								width
								height
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						slug
					}
					featuredPosts {
						node_locale
						title {
							title
						}
						authorone {
							name
						}
						categoryone {
							title
						}
						postType
						description
						createdAt
						excerpt {
							childMarkdownRemark {
								html
							}
						}
						body {
							body
						}
						slug
					}
					content {
						childMarkdownRemark {
							html
						}
					}
				}
			}
		}
	}
`
