import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'

import iconPlayUrl, {
  ReactComponent as IconPlay,
} from '../../static/images/icons/play.svg'

import './styles.scss'

class BlogCardVideoInfo extends PureComponent {
  video = null
  state = {
    duration: 0,
  }

  componentDidMount() {
    if (this.video) {
      this.video.onloadeddata = () => {
        this.setState({ duration: this.video.duration })
      }
    }
  }

  formatVideoDuration(duration) {
    let min, sec
    if (duration > 0) {
      min = (duration / 60).toFixed(0)
      sec = (duration % 60).toFixed(0)
      return `${min}:${sec}`
    } else {
      return
    }
  }
  render() {
    let { data, type, size } = this.props
    let { duration } = this.state
    return (
      <div
        className={`c-info-video ${
          size !== undefined ? `c-info-video--${size}` : ''
        }`}
      >
        {this.props.data.featuredVideo && (
          <video
            className="c-info-video__video"
            ref={video => {
              this.video = video
            }}
            preload="false"
            src={this.props.data.featuredVideo.file.url}
          />
        )}
        <div className="c-info-video__duration">
          {this.formatVideoDuration(duration)} min
        </div>
        <div className={`${size === 'full' ? `c-info-video__full-block` : ''}`}>
          <div
            className={`${size === 'full' ? `c-info-video__left-block` : ''}`}
          >
            <IconPlay className="c-info-video__iconplay" />
          </div>
          <div
            className={`${size === 'full' ? `c-info-video__right-block` : ''}`}
          >
            <div className="c-info-video__category">
              <Link to="#" className="c-info-video__link">
                {data.categoryone.title}
              </Link>
            </div>
            <Link
              to={`${
                data.node_locale == 'en-US' ? '' : `/${data.node_locale}`
              }/blog/${data.slug}`}
              className="c-info-video__link c-info-video__link--block"
            >
              <h2 className="c-info-video__title">{data.title.title}</h2>
              <div className="c-info-video__line" />
              <p className="c-info-video__description">{data.description}</p>
            </Link>
            <div className="c-info-video__submeta">
              <span className="c-info-video__author">
                By{' '}
                <Link to="#" className="c-info-video__link">
                  {data.authorone.name}
                </Link>{' '}
                -{' '}
              </span>
              <time dateTime={data.createdAt} className="c-info-video__date">
                {format(data.createdAt, 'MMM DD YYYY')}
              </time>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogCardVideoInfo
