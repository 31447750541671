import React, { PureComponent } from 'react'

import { Link } from 'gatsby'

import { format } from 'date-fns'
import ReadingTime from '../ReadingTime/ReadingTime'

import './styles.scss'

class BlogCardInfo extends PureComponent {
  getExcerpt() {
    if (this.props.data.excerpt && this.props.type === 'text') {
      return (
        <span
          className="c-info__excerpt"
          dangerouslySetInnerHTML={{
            __html: this.props.data.excerpt.childMarkdownRemark.html,
          }}
        />
      )
    } else {
      return
    }
  }

  render() {
    let { data, type, size } = this.props
    return (
      <div
        className={`c-info ${size !== undefined ? `c-info--${size}` : ''} ${
          type !== undefined ? `c-info--${type}` : ''
        }`}
      >
        <div className="c-info__meta">
          <span className="c-info__category">
            <Link to="#" className="c-info__link">
              {data.categoryone.title}
            </Link>
          </span>
          <ReadingTime className="c-info__read-time" text={data.body.body} />
        </div>
        <div
          className={`${
            size === 'full' && type === 'text' ? 'c-info__full-text' : ''
          }`}
        >
          <div
            className={`${
              size === 'full' && type === 'text' ? 'c-info__full-text-left' : ''
            }`}
          >
            <h2 className="c-info__title">
              <Link
                to={`${
                  data.node_locale == 'en-US' ? '' : `/${data.node_locale}`
                }/blog/${data.slug}`}
                className="c-info__link"
              >
                {data.title.title}
              </Link>
            </h2>
            <div className="c-info__submeta">
              <span className="c-info__author">
                By{' '}
                <Link to="#" className="c-info__link">
                  {data.authorone.name}
                </Link>{' '}
                -{' '}
              </span>
              <time dateTime={data.createdAt} className="c-info__date">
                {format(data.createdAt, 'MMM DD YYYY')}
              </time>
            </div>
          </div>
          <p
            className={`c-info__description ${
              size === 'full' && type === 'text'
                ? 'c-info__description--right'
                : ''
            }`}
          >
            <Link
              to={`${
                data.node_locale == 'en-US' ? '' : `/${data.node_locale}`
              }/blog/${data.slug}`}
              className="c-info__link"
            >
              {data.description}
              {this.getExcerpt()}
            </Link>
          </p>
        </div>
      </div>
    )
  }
}

export default BlogCardInfo
